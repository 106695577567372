<template>
  <div>
    <router-link
      to="/cbt-admin"
      class="text-decoration-none text-subtitle-2 font-weight-bold"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </router-link>
    <h3 class="my-8">
      Tambah CBT
    </h3>
    <v-card>
      <v-card-text class="pa-8">
        <div class="mb-8">
          <h3 class="mb-1">
            Informasi CBT
          </h3>
          <span class="text-subtitle-2 font-weight-light">Informasi untuk CBT</span>
        </div>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div class="mb-4">
              <FileInput
                v-model="initialThumbnail"
                :value="cbt.thumbnail"
                :prependicon="icons.mdiImageMultipleOutline"
                label="Thumbnail"
                outlined
                dense
                @input="getFileThumbnail"
              ></FileInput>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="cbt.name"
                label="Nama CBT"
                outlined
                dense
                :rules="[validation.required]"
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-menu
                v-model="date"
                :close-on-content-click="true"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="cbt.date"
                    label="Tanggal"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    clearable
                    :rules="[validation.required]"
                    v-on="on"
                    @click="autofillDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="cbt.date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="date = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="cbt.duration"
                :rules="[validation.required]"
                label="Durasi (Menit)"
                type="number"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                ref="menu_start"
                v-model="menu_start"
                :close-on-content-click="false"
                nudge-right="32"
                nudge-top="40"
                :return-value.sync="cbt.start_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="cbt.start_time"
                    label="Jam Mulai"
                    :prepend-icon="icons.mdiClockOutline"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    clearable
                    :rules="[validation.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu_start"
                  v-model="cbt.start_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_start.save(cbt.start_time)"
                ></v-time-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                ref="menu_end"
                v-model="menu_end"
                :close-on-content-click="false"
                nudge-right="32"
                nudge-top="40"
                :return-value.sync="cbt.end_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="cbt.end_time"
                    label="Jam Selesai"
                    :prepend-icon="icons.mdiClockOutline"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    clearable
                    :rules="[validation.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu_end"
                  v-model="cbt.end_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_end.save(cbt.end_time)"
                ></v-time-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col md="12">
            <div class="mb-4">
              <h3 class="mb-1">
                Instruksi CBT
              </h3>
              <span class="text-subtitle-2 font-weight-light">Berisi informasi ketetentuan CBT (Contoh: Jumlah Soal, Durasi, Nilai, Tata Tertib, dll)</span>
            </div>
            <div class="my-6">
              <CKEditor
                v-model="cbt.instruction"
                :value="cbt.instruction"
                outlined
                dense
              ></CKEditor>
            </div>
          </v-col>
        </v-row>
        <div class="mb-4">
          <div class="mb-4">
            <h3 class="mb-1">
              Leaderboard CBT
            </h3>
            <span class="text-subtitle-2 font-weight-light">Akan menampilkan leaderboard hasil CBT jika diaktifkan</span>
          </div>
          <div class="d-flex align-center">
            <v-switch
              v-model="leaderboard"
              class="ma-0 pa-0"
              hide-details="none"
              @change="handleSwitch($event)"
            ></v-switch>
            <span class="ms-4">Tampilkan Leaderboard CBT</span>
          </div>
        </div>
        <div
          class="my-6"
        >
          <div class="mb-4">
            <div class="mb-8">
              <h3 class="mb-1">
                Soal
              </h3>
              <span class="text-subtitle-2 font-weight-light">Soal untuk CBT</span>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mb-8">
            <v-card
              v-for="questionItem,index in questionItems"
              v-show="questionItems.length"
              :key="index"
              class="mb-2"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="align-center">
                      <v-col md="2">
                        <span>Soal No {{ index + 1 }}</span>
                      </v-col>
                      <v-col md="4">
                        <span></span>
                        <v-chip
                          outlined
                          class="v-chip-light-bg success--text"
                          h-e-a-d
                          @click="checkDescription(questionItem.name)"
                        >
                          Lihat Deskripsi
                        </v-chip>
                      </v-col>
                      <v-col md="4">
                        <span>A. {{ questionItem.choice_a }}</span>
                      </v-col>
                      <v-col md="1">
                        <a
                          class="text-decoration-none"
                          @click.prevent="showFormEditQuestion(questionItem.uuid)"
                        >Edit</a>
                      </v-col>
                      <v-col md="1">
                        <a
                          class="text-decoration-none"
                          @click.prevent="confirmDestroy(questionItem.uuid)"
                        >Hapus</a>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>Pembahasan</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
          <div
            v-for="(question, index) of questions"
            :key="index"
            class="mb-4"
          >
            <div>
              <div class="d-flex justify-space-between align-center mb-5">
                <div>
                  <h4>
                    Soal Nomor {{ questionItems.length > 0 ? questionItems.length + (index+1) : index+1 }}
                  </h4>
                </div>
                <div>
                  <v-btn
                    color="primary pa-2"
                    icon
                    small
                    @click="closeFormQuestion(index)"
                  >
                    <v-icon>
                      {{ icons.mdiCloseCircleOutline }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="mb-4">
                <CKEditor
                  :value="question[index].name"
                  label="Content"
                  outlined
                  dense
                  @input="handleCKeditor($event, index)"
                ></CKEditor>
              </div>
            </div>
            <div>
              <h4 class="mb-4">
                Masukkan Gambar (jika ada)
              </h4>
              <FileInput
                :value="questionImage[index]"
                label="Gambar"
                outlined
                dense
                :disabled="false"
                required
                :prependicon="icons.mdiImageMultipleOutline"
                @input="getFile"
              ></FileInput>
            </div>
            <div>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <h4 class="mb-4 mt-6">
                    Kunci Jawaban
                  </h4>
                  <v-row>
                    <v-col
                      v-for="questionOption, index in question[index].questionOptions"
                      :key="index"
                      cols="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="py-2"
                    >
                      <div>
                        <v-text-field
                          v-model="questionOption.choice"
                          :disabled="questionOption.file ? true : false"
                          label="Jawaban"
                          outlined
                          dense
                        >
                          <span
                            slot="prepend"
                            class="pt-1"
                          >
                            {{ questionOption.title }}
                          </span>
                        </v-text-field>
                      </div>
                      <div class="ms-4">
                        <FileInput
                          v-model="questionOption.file"
                          :value="questionOption.file"
                          label="Gambar"
                          outlined
                          dense
                          :disabled="questionOption.choice ? true : false"
                          :prependicon="icons.mdiImageMultipleOutline"
                          @input="getFileQuestion"
                        ></FileInput>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-0"
                >
                  <h4 class="my-4">
                    Jawaban Yang Benar
                  </h4>
                  <div class="">
                    <v-autocomplete
                      v-model="question[index].answer"
                      label="Jawaban yang benar"
                      outlined
                      :items="answers"
                      item-text="value"
                      dense
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-0"
                >
                  <h4 class="my-4">
                    Point Jawaban Yang Benar
                  </h4>
                  <div>
                    <v-text-field
                      v-model="question[index].point"
                      label="Point"
                      type="number"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="mb-8 mt-8 d-flex justify-start align-center">
            <v-btn
              text
              small
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              :disabled="!cbt.uuid"
              @click="showFormAddQuestion"
            >
              <v-icon
                color="primary"
                small
                class="me-2"
              >
                {{ icons.mdiPlus }}
              </v-icon> TAMBAH SOAL
            </v-btn>
            <v-btn
              text
              small
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              :disabled="!cbt.uuid"
              @click="showFormImportQuestion"
            >
              <v-icon
                color="primary"
                small
                class="me-2"
              >
                {{ icons.mdiFileOutline }}
              </v-icon> IMPORT SOAL
            </v-btn>
            <v-btn
              text
              small
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              :disabled="!cbt.uuid"
              @click="exportQuestion"
            >
              <v-icon
                color="primary"
                small
                class="me-2"
              >
                {{ icons.mdiTrayArrowDown }}
              </v-icon> DOWNLOAD TEMPLATE
            </v-btn>
          </div>
          <div v-show="isHide">
            <div>
              <v-card-actions class="d-flex justify-center my-8">
                <v-btn
                  color="primary"
                  large
                  :loading="isLoadingButton"
                  :disabled="!formQuestion"
                  @click="addQuestion()"
                >
                  Simpan
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  large
                  @click="cancelAddQuestion"
                >
                  Batal
                </v-btn>
              </v-card-actions>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <v-card-actions
          v-if="!cbt.uuid"
          class="justify-center pa-0 mt-4"
        >
          <v-btn
            color="primary"
            large
            :loading="isLoadingButton"
            :disabled="!formValid"
            @click="addCbt()"
          >
            Simpan
          </v-btn>
          <v-btn
            color="primary"
            outlined
            large
            @click="cancelAction"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      header="Edit Soal"
      text-button="Update"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"

      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
      @handler-buttton="updateQuestion"
    >
      <template v-slot:body>
        <div class="mb-4">
          <CKEditor
            :value="initialContent"
            label="Soal"
            outlined
            dense
            @input="handleCKeditor($event, 'edit')"
          ></CKEditor>
        </div>
        <div>
          <h4 class="mb-4">
            Masukkan Gambar (jika ada)
          </h4>
          <FileInput
            v-model="initialFile"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFile"
          ></FileInput>
        </div>
        <div>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Jawaban Yang Benar
              </h4>
              <div class="">
                <v-autocomplete
                  v-model="questionEdit.answer.answer"
                  label="Jawaban yang benar"
                  outlined
                  :items="answers"
                  item-text="value"
                  dense
                >
                </v-autocomplete>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Point Jawaban Yang Benar
              </h4>
              <div>
                <v-text-field
                  v-model="questionEdit.answer.point"
                  label="Point"
                  type="number"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDialogImport"
      header="Import Soal"
      text-button="Import"
      :is-loading-button="isLoadingButton"
      @handler-button="importQuestion"
      @close-modal="modalDialogImport = false"
      @close-button="modalDialogImport = false"
    >
      <template v-slot:body>
        <FileInput
          v-model="importFile"
          label="File"
          outlined
          dense
          :disabled="false"
          :accept="accept"
          :prependicon="icons.mdiImageMultipleOutline"
          @input="getFileImport"
        ></FileInput>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi Soal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Soal"
      @close-button="confirmDialog = false"
      @destroy-button="destroyQuestion"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import CKEditor from '../../components/CkEditor.vue'

// eslint-disable-next-line object-curly-newline
import {
  mdiArrowLeft,
  mdiClockOutline,
  mdiCloseCircleOutline,
  mdiDotsVertical,
  mdiImageMultipleOutline,
  mdiFileOutline,
  mdiTrayArrowDown,
  mdiPlus,
} from '@mdi/js'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'CbtAdd',
  components: {
    FileInput,
    ModalDialog,
    ConfirmDialog,
    CKEditor,
  },
  data() {
    return {
      modalDescription: false,
      widthModal: 810,
      icons: {
        mdiDotsVertical,
        mdiPlus,
        mdiArrowLeft,
        mdiImageMultipleOutline,
        mdiClockOutline,
        mdiCloseCircleOutline,
        mdiFileOutline,
        mdiTrayArrowDown,
      },
      validation: {
        required,
        integerValidator,
      },
      isLoadingButton: false,
      isDisabledButton: false,
      answers: [
        { index: 'a', value: 'A' },
        { index: 'b', value: 'B' },
        { index: 'c', value: 'C' },
        { index: 'd', value: 'D' },
      ],
      question: [],
      questions: [],
      questionImage: [],
      questionItems: [],
      questionItem: {},
      questionEdit: {
        name: null,
        answer: {
          answer: '',
          point: '',
        },
      },
      numberQuestion: 0,

      modalDialog: false,
      modalDialogImport: false,
      confirmDialog: false,
      isHide: false,
      date: false,
      service: 'cbt',
      time: null,
      menu_start: false,
      menu_end: false,
      cbt: {
        uuid: '',
        thumbnail: null,
        name: '',
        type: 'admin',
        type_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
        duration: '',
        start_time: null,
        end_time: null,
        date: '',
        instruction: 'Instruksi',
        show_leaderboard: 1,
      },
      leaderboard: true,
      accept: '.xlsx',
      tempContent: '',
      tempImage: [],
      detail: '',
      formValid: false,
      formQuestion: false,
      importFile: null,
      questionFile: [],
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.questionEdit.image === 'string') {
          return new File([this.questionEdit.image], this.questionEdit.image, { type: 'text/plain' })
        }

        return this.questionEdit.image
      },
      set() {
        return this.questionEdit.image
      },
    },
    initialThumbnail: {
      get() {
        if (typeof this.cbt.thumbnail === 'string') {
          return new File([this.cbt.thumbnail], this.cbt.thumbnail, { type: 'text/plain' })
        }

        return this.cbt.thumbnail
      },
      set() {
        return this.cbt.thumbnail
      },
    },
    initialContent: {
      get() {
        if (this.questionEdit.name === null) {
          return ''
        }

        return this.questionEdit.name
      },
      set() {
        return this.questionEdit.name
      },
    },
  },
  watch: {
    cbt: {
      handler() {
        const valid = []
        const requiredField = ['name', 'start_time', 'end_time', 'duration', 'date']
        Object.entries(this.cbt).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    question: {
      handler() {
        console.log(this.question)
        const valid = []
        const requiredField = ['name', 'choice_a', 'choice_b', 'choice_c', 'choice_d', 'answer', 'point']
        this.question.forEach((key, item) => {
          console.log(key)
          console.log(item)
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })

        this.formQuestion = !valid.includes(false)
      },
      deep: true,
    },
  },
  mounted() {
    localStorage.removeItem('cbt')
  },
  methods: {
    checkDescription(data) {
      this.detail = data

      this.modalDescription = true
    },
    cancelAction() {
      this.modalDialog = false
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''

      return this.$router.back()
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event, index) {
      if (index !== 'edit') {
        this.question[index].name = event
      } else {
        this.questionEdit.name = event
      }

      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    getFile(data) {
      this.questionImage.push(data)
    },
    getFileThumbnail(data) {
      this.cbt.thumbnail = data
      console.log(this.cbt.thumbnail)
    },
    getFileImport(data) {
      this.importFile = data
    },
    getFileQuestion(data) {
      this.questionFile.push(data)
    },
    returnBack() {
      return this.$router.back()
    },
    autofillDate() {
      this.cbt.date = new Date().toISOString().substr(0, 10)
    },
    showFormAddQuestion() {
      this.tempContent = ''
      this.tempImage = []
      this.question = [
        ...this.question,
        {
          name: '',
          number: 0,
          image: null,
          choice_a: '',
          choice_b: '',
          choice_c: '',
          choice_d: '',
          answer: '',
          point: '',
          questionOptions: [
            {
              index: 1,
              vModel: 'choice_a',
              title: 'A',
              choice: '',
              file: null,
            },
            {
              index: 2,
              vModel: 'choice_b',
              title: 'B',
              choice: '',
              file: null,
            },
            {
              index: 3,
              vModel: 'choice_c',
              title: 'C',
              choice: '',
              file: null,
            },
            {
              index: 4,
              vModel: 'choice_d',
              title: 'D',
              choice: '',
              file: null,
            },
          ],
        },
      ]
      this.question.number = this.questionItems.length + 1
      this.questions.push(this.question)
      this.isHide = true
    },
    showFormImportQuestion() {
      this.modalDialogImport = true
    },
    async getCbt() {
      await this.$services.ApiServices.get(this.service, this.cbt.uuid).then(
        ({ data }) => {
          this.cbt = data.data
          this.cbt.date = this.cbt.start_date.slice(0, 10)
          this.cbt.start_time = this.cbt.start_date.slice(11, 16)
          this.cbt.end_time = this.cbt.end_date.slice(11, 16)
          this.questionItems = this.cbt.question
        },
        err => console.error(err),
      )
    },
    async addCbt() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.cbt.start_date = `${this.cbt.date} ${this.cbt.start_time}`
      this.cbt.end_date = `${this.cbt.date} ${this.cbt.end_time}`
      this.formData = new FormData()
      Object.keys(this.cbt).forEach((key, item) => {
        this.formData.append(key, this.cbt[key])
      })
      await this.$services.ApiServices.add(this.service, this.formData).then(
        async ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.cbt = data.data
          this.isLoadingButton = false
          this.isDisabledButton = false
          await this.getCbt()
          localStorage.setItem('cbt', JSON.stringify(data.data))
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    async addQuestion() {
      this.isLoadingButton = true
      this.isDisabledButton = true

      if (this.cbt.uuid === '') {
        await this.addCbt()
      }
      await this.question.forEach(async (item, index) => {
        item.number = this.questionItems.length + (index + 1)
        item.cbt_uuid = JSON.parse(localStorage.getItem('cbt')).uuid
        this.formData = new FormData()
        this.formData.append('cbt_uuid', item.cbt_uuid)
        this.formData.append('number', item.number)
        this.formData.append('name', item.name)
        this.formData.append('image', this.questionImage[index])
        item.questionOptions.forEach(el => {
          this.formData.append(el.vModel, el.choice !== '' ? el.choice : el.file)
        })
        this.formData.append('answer', item.answer)
        this.formData.append('point', item.point)
        if (typeof this.questionImage[index] === 'undefined') {
          this.formData.delete('image')
        }
        const tempImage = this.tempContent.split('"')
        tempImage.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
        const image = item.name.split('"')
        image.forEach(item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            const carIndex = this.tempImage.indexOf(img)
            this.tempImage.splice(carIndex, 1)

            this.tempImage.forEach(async item => {
              await this.deleteStorage({ file: item })
            })
          }
        })
        await this.$services.ApiServices.add('questioncbt', this.formData).then(
          ({ data }) => {
            this.showSnackbar({
              text: data.result.message,
              color: 'success',
            })
          },
          err => {
            this.isLoadingButton = false
            console.error(err)
          },
        )
        await this.getCbt(this.cbt.uuid)
        this.isHide = false
        this.numberQuestion = this.questionItems.length
        this.questions.splice(index, this.questions.length)
        if (this.questions.length < 1) {
          this.isHide = false
        }
        this.question = []
        this.questionImage = []
        this.isLoadingButton = false
        this.isDisabledButton = false
      })
    },
    showFormEditQuestion(uuid) {
      this.tempContent = ''
      this.tempImage = []
      this.questionEdit.uuid = uuid
      this.modalDialog = true
      this.$services.ApiServices.get('questioncbt', uuid).then(
        ({ data }) => {
          this.questionEdit = data.data
          const image = data.data.name.split('"')
          image.forEach(async item => {
            const img = item.split('/').pop()
            if (img.length === 44) {
              this.tempImage.push(img)
            }
          })
        },
        err => console.error(err),
      )
    },
    async updateQuestion() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      this.formData = new FormData()
      this.formData.append('number', this.questionEdit.number)
      this.formData.append('name', this.questionEdit.name)
      this.formData.append('choice_a', this.questionEdit.choice_a)
      this.formData.append('choice_b', this.questionEdit.choice_b)
      this.formData.append('choice_c', this.questionEdit.choice_c)
      this.formData.append('choice_d', this.questionEdit.choice_d)
      this.formData.append('answer', this.questionEdit.answer.answer)
      this.formData.append('point', this.questionEdit.answer.point)
      if (typeof this.questionEdit.image !== 'string') {
        this.formData.delete('image')
      }
      const image = this.questionEdit.name.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('questioncbt', this.formData, this.questionEdit.uuid).then(
        async ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          await this.getCbt()
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.modalDialog = false
    },
    confirmDestroy(uuid) {
      this.questionItem.uuid = uuid
      this.confirmDialog = true
    },
    async destroyQuestion() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.destroy('questioncbt', this.questionItem.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.confirmDialog = false
      await this.getCbt()
    },
    async importQuestion() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('file', this.importFile)
      await this.$services.ImportParamsServices.import('importcbt', this.cbt.uuid, this.formData).then(
        async ({ data }) => {
          this.showSnackbar({
            text: 'Import question cbt has successfully',
            color: 'success',
          })

          await this.getCbt()
        },
        err => {
          this.isLoadingButton = false
          this.modalDialogImport = false
          console.error(err)
        },
      )
      this.modalDialogImport = false
      this.isLoadingButton = false
      this.importFile = null
    },
    async exportQuestion() {
      await this.$services.ExportServices.export('exportcbt').then(data => {
        const url = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'template-cbt.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
    closeFormQuestion(index) {
      this.questions.splice(index, 1)
      this.question.splice(index, 1)
      if (this.questions.length < 1) {
        this.isHide = false
      }
    },
    cancelAddQuestion() {
      this.questions = []
      this.isHide = false
    },
    handleSwitch(event) {
      if (event !== false) {
        this.cbt.show_leaderboard = 1
      } else {
        this.cbt.show_leaderboard = 0
      }
      console.log(this.cbt.show_leaderboard)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.match-height > [class*='col'] {
  display: flex !important;
  flex-flow: column !important;
}

.match-height > [class*='col'] > .v-card {
  flex: 1 1 auto !important;
}
</style>
